table, tr {
    color :white;
}


thead {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

thead th {
    cursor: pointer;
}